import * as React from "react";
import {
    Modal,
    Box,
    TextField,
    Button,
    Typography,
    Switch,
} from "@mui/material";
import { Formik, Form } from "formik";
import usePost from "hooks/usePost";

export default function AddBillModal({
    open,
    handleClose,
    property,
    AID,
    LID,
    refetch
}) {

    console.log(LID)

    const initialValues = {
        property: property.split("-")[0],
        apartment_id: AID,
        amount: "",
        lid: LID,
        rent_name: "Monthly Rent",
        payment_term: "monthly",
        payment_date: "",
        is_auto: false,
    };

    const { mutate } = usePost("/bill/loan_rent/manual_generate", "post", {
        onSuccess: (response) => {
            alert("Bill Created")
            refetch();
        },
        onError: (error) => {
            alert("error creating bill");
            refetch();
        },
    });

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Create Monthly Bill
                </Typography>
                <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        // console.log(values);
                        mutate(values);
                        setSubmitting(false);
                        handleClose();
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        touched,
                        errors,
                        isSubmitting,
                    }) => (
                        <Form>
                            <div style={{ display: "flex", gap: "3rem", marginTop: "1rem" }}>
                                <TextField
                                    fullWidth
                                    id="property"
                                    name="property"
                                    label="PID:"
                                    type="text"
                                    value={values.property}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.property && Boolean(errors.property)}
                                    helperText={touched.property && errors.property}
                                />
                                <TextField
                                    fullWidth
                                    id="apartment_id"
                                    name="apartment_id"
                                    label="LAID"
                                    type="text"
                                    value={values.apartment_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.apartment_id && Boolean(errors.apartment_id)}
                                    helperText={touched.apartment_id && errors.apartment_id}
                                />
                                <TextField
                                    fullWidth
                                    id="lid"
                                    name="lid"
                                    label="LID"
                                    type="text"
                                    value={values.lid}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.lid && Boolean(errors.lid)}
                                    helperText={touched.lid && errors.lid}
                                />
                            </div>
                            <div style={{ display: "flex", gap: "3rem", margin: "2rem 0" }}>
                                <TextField
                                    fullWidth
                                    id="amount"
                                    name="amount"
                                    label="AMOUNT"
                                    type="text"
                                    value={values.amount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.amount && Boolean(errors.amount)}
                                    helperText={touched.amount && errors.amount}
                                />
                                <TextField
                                    fullWidth
                                    id="rent_name"
                                    name="rent_name"
                                    label="RENT NAME"
                                    type="text"
                                    value={values.rent_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.rent_name && Boolean(errors.rent_name)}
                                    helperText={touched.rent_name && errors.rent_name}
                                />
                                <TextField
                                    fullWidth
                                    id="payment_term"
                                    name="payment_term"
                                    label="Payment Term"
                                    type="text"
                                    value={values.payment_term}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.payment_term && Boolean(errors.payment_term)}
                                    helperText={touched.payment_term && errors.payment_term}
                                />
                            </div>
                            <div>
                                <p>Payment Date</p>
                                <TextField
                                    fullWidth
                                    id="payment_date"
                                    name="payment_date"
                                    label=""
                                    type="date"
                                    value={values.payment_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.payment_date && Boolean(errors.payment_date)}
                                    helperText={touched.payment_date && errors.payment_date}
                                />
                            </div>

                            <div className="form-toggle" style={{ marginTop: '3rem' }}>
                                <h4>Is Auto</h4>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p>Yes</p>
                                    <Switch
                                        id="is_auto"
                                        name="is_auto"
                                        checked={values.is_auto}
                                        onChange={handleChange}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                    <p>No</p>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: "3rem",
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    Add Bill
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
}
