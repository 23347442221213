export const API_URLS = {
  // AUTH_SERVICE_STAGING: 'https://staging-auth-api.liveizy.com/api',
  AUTH_SERVICE_STAGING: "https://staging-auth-api.liveizy.com/api",
  AUTH_SERVICE_PRODUCTION: "https://auth-api.liveizy.com/api",
  IZYPROMAN_LOCAL: "http://localhost:5000/api",
  IZYPROMAN_DEV: "https://staging.property-service.liveizy.com/api",
  IZYPROMAN_STAGING: "https://staging.property-service.liveizy.com/api",
  IZYBIZ_DEV: "https://staging-api-izybiz.liveizy.com/api/v1",
  IZYBIZ_PRODUCTION: "https://izybiz-api.liveizy.com/api/v1",
  IZYPROMAN_PRODUCTION: "https://property-api.liveizy.com/api",
  WALLET_SERVICE_STAGING: "https://staging-wallet-api.liveizy.com/api",
  WALLET_SERVICE_PRODUCTION: "https://wallet-api.liveizy.com/api",
};

export const FRONTEND_URLS = {
  ADMIN_FRONTEND_DEV: "https://izyproman-admin-client.herokuapp.com",
  ADMIN_FRONTEND_STAGING: "https://staging-admingui.liveizy.com",
  ADMIN_FRONTEND_PRODUCTION: "https://admin.liveizy.com",
};
