import React, { useState, useEffect } from "react";
import { Container } from "./styles/propertyStyles";
import { izyBizService, izypromanService } from "../../../../apis";
import { CircularProgress, MenuItem, Select, styled } from "@mui/material";
import ActiveMonthlyRent from "./activeStage/ActiveMonthlyRent";
import StageOne from "./stageOne/StageOne";
import StageTwo from "./stageTwo/StageTwo";
import { CustomBtn } from "shared/components";
import { useNavigate } from "react-router-dom";
import BackComp from "components/parts/BackComp";
import useFetch from "hooks/useFetch";
import ActiveRenewals from "./renewal";

const MonthlyRent = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [stageOneApplicants, setStageOneApplicants] = useState([]);
  const [stageTwoApplicants, setStageTwoApplicants] = useState([]);
  const [activeApplicants, setActiveApplicants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const goToSettings = () => {
    navigate("/config");
  };

  const { data: liveizyBillUsers, loading: LBUIsLoading } = useFetch(izyBizService(), `/liveizy-bill/active-users`, {
    errorMessage: "Error getting data",
  });

  const { data, error, loading } = useFetch(izypromanService(), `/application/all?page=${currentPage}&limit=${rowsPerPage}`, {
    errorMessage: "Error getting data",
  });

  const { data: renewal, loading: renewalsLoading } = useFetch(izypromanService(), `/renewal-applications?page=${currentPage}&limit=${rowsPerPage}`, {
    errorMessage: "Error getting data",
  });


  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //       const response = await izyBizService().get('liveizy-bill/active-users');
  //       setActiveApplicants(response?.data);
  //     } catch (err) {
  //       // setError("Error getting data");
  //     } finally {
  //       // setLBUIsLoading(false);
  //     }
  //   };

  //   fetchUsers();
  // }, []);


  useEffect(() => {
    const fetchApplicants = async () => {
      if (data && liveizyBillUsers) {
        const { payload } = data;
        const fetchedApplicants = payload?.applicants || [];

        try {
          const fullApplicantDetails = fetchedApplicants

          const stageOne = fullApplicantDetails?.filter(
            (application) =>
              ["pending", "verified", "review", "failed"].includes(
                application.monthly_rent_status_stage_1
              ) && application.monthly_rent_status_stage_2 === "none"
          );

          const stageTwo = fullApplicantDetails?.filter(
            (application) =>
              ["pending", "accepted", "review"].includes(
                application.monthly_rent_status_stage_2
              ) && application.monthly_rent_status_stage_1 === "verified" && !application.active_invoice
          );

          // const active = fullApplicantDetails.filter(
          //   (application) =>
          //     application.monthly_rent_status_stage_1 === "verified" &&
          //     application.monthly_rent_status_stage_2 === "accepted" &&
          //     application.active_invoice
          // );

          setStageOneApplicants(stageOne);
          setStageTwoApplicants(stageTwo);
          setActiveApplicants(liveizyBillUsers?.data || []);

        } catch (err) {
          console.error("Error fetching user data: ", err);
        }
      }
    };

    fetchApplicants();
  }, [data, liveizyBillUsers]);

  let tabComponent;
  switch (currentTab) {
    case 0:
      tabComponent = <ActiveMonthlyRent applicants={activeApplicants} />;
      break;
    case 1:
      tabComponent = <StageOne applicants={stageOneApplicants} />;
      break;
    case 2:
      tabComponent = <StageTwo applicants={stageTwoApplicants} />;
      break;
    case 3:
      tabComponent = <ActiveRenewals applicants={renewal?.data?.data} />
      break;
    default:
      break;
  }

  return (
    <>
      <Container style={{ display: "grid", gap: "1rem" }}>
        <BackComp />
        {error && <p>{error}</p>}
        <Tabs>
          <Tab className={currentTab === 0 ? "active" : ""} onClick={() => setCurrentTab(0)}>
            Active Monthly Rent
          </Tab>
          <Tab className={currentTab === 1 ? "active" : ""} onClick={() => setCurrentTab(1)}>
            Verification Stage 1
          </Tab>
          <Tab className={currentTab === 2 ? "active" : ""} onClick={() => setCurrentTab(2)}>
            Verification Stage 2
          </Tab>
          <Tab className={currentTab === 3 ? "active" : ""} onClick={() => setCurrentTab(3)}>
            Renewals
          </Tab>
        </Tabs>
        <CustomBtn onClick={goToSettings} style={{ width: "100px" }}>
          Settings
        </CustomBtn>
        <Container>{
          loading || renewalsLoading || LBUIsLoading ?
            <Loader>
              <CircularProgress />
            </Loader> : (
              <>
                <div>
                  {tabComponent}
                </div>
                <PaginationControls
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalItems={renewal?.data?.totalPages || data?.payload?.totalItems}
                />
              </>
            )}
        </Container>
      </Container>
    </>
  );
};

const PaginationControls = ({ currentPage, setCurrentPage, rowsPerPage, setRowsPerPage, totalItems }) => {
  const totalPages = Math.ceil(totalItems / rowsPerPage);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
      <CustomBtn
        style={{ height: 'auto', width: 'auto', padding: '12px 2rem' }}
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}
      >
        Previous
      </CustomBtn>

      <span style={{ fontSize: '1.1rem', fontWeight: '500' }}>{`Page ${currentPage} of ${totalPages}`}</span>

      <CustomBtn
        style={{ height: 'auto', width: 'auto', padding: '12px 2rem' }}
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}
      >
        Next
      </CustomBtn>

      <Select value={rowsPerPage} onChange={(e) => setRowsPerPage(Number(e.target.value))}>
        {[5, 10, 20, 50].map(size => (
          <MenuItem key={size} value={size}>{size} per page</MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default MonthlyRent;

const Loader = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
`;

const Tabs = styled("div")`
  margin: 0 auto;
  text-transform: uppercase;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  gap: 20px;
`;

const Tab = styled("div")`
  padding: 15px;
  font-size: 13px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);

  &.active {
    background: #10609f;
    color: #fff;
  }
`;
