import React from "react";
import { Card } from "../styles/propertyStyles";
import { useNavigate } from "react-router-dom";
import styles from "../styles/property.module.css";
import DataTable from "components/parts/DataTable";
import { Button } from "@mui/material";

function getDate(params) {
  return `${new Date(params.row.createdAt).toLocaleDateString() || `-`}`
}

const ActiveMonthlyRent = ({ applicants }) => {
  const navigate = useNavigate();

  const handleView = (liveizy_id, apartment_id) => {
    navigate(`/property/monthly-rent/active/${liveizy_id}/${apartment_id}`);
  };

  const usersTableColumns = [
    {
      field: 'fullName',
      headerName: "User Name",
      width: 300,
      headerClassName: 'bold-header',
     valueGetter: (params) => `${params?.row?.name || ''}`
    },
    { field: 'apartment_id', headerName: "Apartment ID", width: 300, headerClassName: 'bold-header' },
    { field: "lid", headerName: "Tenant LID", width: 300, headerClassName: 'bold-header' },
    { field: 'createdAt', headerName: "Date Applied", width: 300, valueGetter: getDate, headerClassName: 'bold-header' },
    {
      field: "view",
      headerName: "",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            color="primary"
            sx={{ background: "#10609F", fontSize: "10px", color: "white" }}
            onClick={() => handleView(params?.row?.lid, params?.row?.apartment_id)}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <p className={styles.awaitingListing}> Accounts with monthly rent </p>
        <h1 className={styles.awaitingListingCount}>{applicants?.length}</h1>
      </Card>

      <div style={{ margin: '50px 0' }}>
        <DataTable
          autoHeight
          rows={applicants}
          columns={usersTableColumns}
          getRowId={(row) => row?.lid}
        />
      </div>

      {/* <AwaitingListBody>
          <table className={styles.awaitingListTable}>
            <thead>
              <tr className={styles.tableRow}>
                <th>User Name</th>
                <th>Property ID</th>
                <th>Apartment ID</th>
                <th>Tenant LID</th>
                <th>Date Applied</th>
                <th></th>
              </tr>
            </thead>
            {applicants.map((rent) => (
              <tbody key={rent.lid || `-`}>
                <tr key="{rent.index}">
                  <td>{rent.fullName || `-`}</td>
                  <td>{rent.laid}</td>
                  <td>{rent.apartment_id || `-`}</td>
                  <td>{rent.lid || `-`}</td>
                  <td>{new Date(rent.createdAt).toLocaleDateString() || `-`}</td>
                  <td>
                    <button onClick={() => handleView(rent.lid)}>View</button>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </AwaitingListBody> */}
    </>
  );
};

export default ActiveMonthlyRent;
